<template>
    <b-container>
        <section class="category-details-section py-5 overflow-hidden">
            <b-row>
                <b-col cols="12" md="6">
                    <div
                        class="mx-auto"
                        style="max-width: 500px"
                        data-aos="fade-right"
                        data-aos-duration="3000"
                    >
                        <h5 class="text-custom-blue-sky mb-2">
                            {{ webCategoryDto.name }}
                        </h5>
                        <p class="m-0 text-white text-justify">
                            {{ webCategoryDto.description }}
                        </p>
                    </div>
                </b-col>
                <b-col cols="12" md="6" class="d-flex align-items-center">
                    <div
                        data-aos="fade-left"
                        data-aos-duration="3000"
                        class="category-description-container text-center w-100 rounded mx-auto"
                        style="max-width: 300px"
                    >
                        <img
                            :src="
                                $store.getters['app/domainHost'] +
                                    '/' +
                                    webCategoryDto.imagePath
                            "
                            class="w-100 rounded"
                        />
                    </div>
                </b-col>
            </b-row>
        </section>
        <section class="sub-category-section py-5">
            <b-col cols="12" data-aos="fade-up" data-aos-duration="3000">
                <h4 class="text-custom-blue-sky">
                    {{ t("website.subCategories") }}
                </h4>
                <swiper
                    :options="swiperOptions"
                    class="products-carousel mt-4 mt-md-0"
                    ref="mySwiper"
                    v-if="webCategoryDto.childCategory.length && showSlider"
                >
                    <swiper-slide
                        v-for="(subCat, i) in webCategoryDto.childCategory"
                        :key="i"
                    >
                        <div class="slide-sub-category mx-auto">
                            <div class="image-2">
                                <div class="category-image-card-border rounded">
                                    <div class="category-image-card rounded">
                                        <div class="sub-category rounded"></div>
                                    </div>
                                </div>
                                <router-link
                                    :to="
                                        '/products?mainCategoryId=' +
                                            id +
                                            '&subCategoryId=' +
                                            subCat.id
                                    "
                                    custom
                                >
                                    <div class="category-content">
                                        <img
                                            :src="
                                                $store.getters[
                                                    'app/domainHost'
                                                ] +
                                                    '/' +
                                                    subCat.imagePath
                                            "
                                            style="object-fit: cover"
                                        />
                                        <h5 class="m-0 text-white mt-75">
                                            <strong>{{ subCat.name }}</strong>
                                        </h5>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <h5 class="text-center text-white" v-else>
                    {{ t("website.nosubcat") }}
                </h5>
            </b-col>
        </section>
    </b-container>
</template>

<script>
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapActions, mapState } from "vuex";
import { handleLangChange } from "@/libs/event-bus";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
export default {
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        id: String
    },
    metaInfo() {
        return {
            titleTemplate:
                "Awija - " +
                this.$t("website.categories") +
                " " +
                this.webCategoryDto.name,
            htmlAttrs: {
                lang: this.$i18n.locale
            },
            meta: [
                {
                    name: "description",
                    content: this.webCategoryDto.description
                },
                {
                    name: "keywords",
                    content: "keywords, keywords, keywords, ..."
                }
            ]
        };
    },
    setup() {
        const { t } = useI18nUtils();
        return { t };
    },
    data: () => ({
        swiperOptions: {
            slidesPerView: 1,
            centeredSlides: true,
            loop: true
        },
        lengthItems: 3,
        showSlider: true
    }),
    computed: {
        ...mapState({
            webCategoryDto: state => state.webProducts.webCategoryDto,
            lang: state => state.app.lang
        })
    },
    created() {
        addEventListener("resize", this.settings);
        this.getCategoryWebsiteDetails(this.id).then(({ data }) => {
            this.$store.commit("Get_Category_Website_Details", data[0]);
            this.lengthItems = data[0].childCategory.length;
            this.settings();
        });
        handleLangChange(() => {
            addEventListener("resize", this.settings);
            this.getCategoryWebsiteDetails(this.id).then(({ data }) => {
                this.$store.commit("Get_Category_Website_Details", data[0]);
                this.lengthItems = data[0].childCategory.length;
                this.settings();
            });
        });
    },
    methods: {
        ...mapActions(["getCategoryWebsiteDetails"]),
        settings() {
            this.showSlider = false;
            const screen =
                this.lengthItems <= 5
                    ? 1
                    : innerWidth < 567
                    ? 1
                    : innerWidth < 992
                    ? 3
                    : innerWidth < 1200
                    ? 5
                    : 5;
            this.swiperOptions = {
                slidesPerView: screen,
                centeredSlides: true,
                loop: true
            };
            this.$nextTick(() => {
                this.showSlider = true;
            });
        }
    }
};
</script>

<style scoped lang="scss">
.category-details-section {
    .category-description-container {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            width: 200px;
            height: 20px;
            background: rgba(0, 0, 0, 0.6);
            filter: blur(8px);
            border-radius: 50%;
        }
    }
}
.sub-category-section {
    .slick-initialized {
        min-height: 260px;
    }
    .slide-sub-category {
        max-width: 200px;
        height: 230px;
        position: relative;
        transform: scale(0.6);
        transition: transform 0.3s, height 1s;
        h5 {
            opacity: 0;
            visibility: hidden;
            transition: 1s;
            position: absolute;
            bottom: 50px;
            left: 12px;
        }
        .image-2 {
            perspective: 500px;
            transform-style: preserve-3d;
            position: relative;
            height: 100%;
            width: 100%;
            .category-image-card-border {
                transform-origin: right center;
                transform: scale(1.1) translate3d(-20px, -12px, -152px)
                    rotate3d(24, 28, 0, -40deg) skew(-6deg, -2deg);
                padding: 3px;
                height: 100%;
                width: 100%;
                background: linear-gradient(
                    0deg,
                    #878787,
                    #ffffff,
                    #6d6d6d,
                    #ffffff,
                    #949494
                );
                .category-image-card {
                    height: 100%;
                    width: 100%;
                }
            }
            .category-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                img {
                    margin-top: 18px;
                    margin-right: -40px;
                    width: 100%;
                    max-width: 160px;
                    max-height: 160px;
                    object-fit: cover;
                }
            }
        }
        .sub-category {
            background: var(--custom-dark);
            height: 100%;
        }
    }
    .swiper-slide-active {
        .slide-sub-category {
            transform: scale(1);
            height: 260px;
            h5 {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    ::v-deep .slick-prev {
        left: -10px !important;
        right: auto !important;
    }
    ::v-deep .slick-arrow {
        height: auto !important;
    }
    ::v-deep .slick-arrow:before {
        color: transparent !important;
        font-size: 0 !important;
    }
    ::v-deep .slick-next {
        right: -10px !important;
    }
}

[dir="ltr"] {
    .sub-category-section {
        .slide-sub-category {
            .image-2 {
                .category-image-card-border {
                    transform: scale(1.1) translate3d(-20px, -12px, -152px)
                        rotate3d(24, 28, 0, 40deg) skew(-6deg, -2deg);
                }
            }
        }
    }
}
</style>
<style lang="scss">
.arrow {
    z-index: 100;
}
[dir="rtl"] {
    .arrow {
        transform: scaleX(-1);
    }
}
</style>
