var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('section',{staticClass:"category-details-section py-5 overflow-hidden"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"500px"},attrs:{"data-aos":"fade-right","data-aos-duration":"3000"}},[_c('h5',{staticClass:"text-custom-blue-sky mb-2"},[_vm._v(" "+_vm._s(_vm.webCategoryDto.name)+" ")]),_c('p',{staticClass:"m-0 text-white text-justify"},[_vm._v(" "+_vm._s(_vm.webCategoryDto.description)+" ")])])]),_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"category-description-container text-center w-100 rounded mx-auto",staticStyle:{"max-width":"300px"},attrs:{"data-aos":"fade-left","data-aos-duration":"3000"}},[_c('img',{staticClass:"w-100 rounded",attrs:{"src":_vm.$store.getters['app/domainHost'] +
                                '/' +
                                _vm.webCategoryDto.imagePath}})])])],1)],1),_c('section',{staticClass:"sub-category-section py-5"},[_c('b-col',{attrs:{"cols":"12","data-aos":"fade-up","data-aos-duration":"3000"}},[_c('h4',{staticClass:"text-custom-blue-sky"},[_vm._v(" "+_vm._s(_vm.t("website.subCategories"))+" ")]),(_vm.webCategoryDto.childCategory.length && _vm.showSlider)?_c('swiper',{ref:"mySwiper",staticClass:"products-carousel mt-4 mt-md-0",attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.webCategoryDto.childCategory),function(subCat,i){return _c('swiper-slide',{key:i},[_c('div',{staticClass:"slide-sub-category mx-auto"},[_c('div',{staticClass:"image-2"},[_c('div',{staticClass:"category-image-card-border rounded"},[_c('div',{staticClass:"category-image-card rounded"},[_c('div',{staticClass:"sub-category rounded"})])]),_c('router-link',{attrs:{"to":'/products?mainCategoryId=' +
                                        _vm.id +
                                        '&subCategoryId=' +
                                        subCat.id,"custom":""}},[_c('div',{staticClass:"category-content"},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.$store.getters[
                                                'app/domainHost'
                                            ] +
                                                '/' +
                                                subCat.imagePath}}),_c('h5',{staticClass:"m-0 text-white mt-75"},[_c('strong',[_vm._v(_vm._s(subCat.name))])])])])],1)])])}),1):_c('h5',{staticClass:"text-center text-white"},[_vm._v(" "+_vm._s(_vm.t("website.nosubcat"))+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }